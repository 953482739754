import { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaClock, FaGlobeEurope, FaSatellite, FaShip, FaPlane, FaMobileAlt } from 'react-icons/fa';

const ApplicationsContainer = styled.div`
  padding: 120px 0 4rem;
  min-height: 100vh;
`;

const ApplicationsContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const ApplicationsHeader = styled.div`
  text-align: center;
  margin-bottom: 4rem;

  h1 {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }

  p {
    color: var(--dark-gray);
    font-size: 1.1rem;
    max-width: 800px;
    margin: 0 auto;
  }
`;

const ApplicationsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
`;

const ApplicationCard = styled(motion.div)`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  svg {
    font-size: 2.5rem;
    color: var(--accent-color);
    margin-bottom: 1rem;
  }

  h3 {
    color: var(--primary-color);
    margin-bottom: 1rem;
  }

  p {
    color: var(--dark-gray);
    line-height: 1.6;
  }
`;

const DetailModal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
`;

const ModalContent = styled(motion.div)`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;

  h2 {
    color: var(--primary-color);
    margin-bottom: 1rem;
  }

  p {
    color: var(--dark-gray);
    line-height: 1.8;
    margin-bottom: 1.5rem;
  }

  button {
    background-color: var(--accent-color);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--primary-color);
    }
  }
`;

const applications = [
  {
    id: 1,
    title: 'Temps Universel',
    description: 'Synchronisation mondiale du temps basée sur le méridien de Greenwich.',
    icon: FaClock,
    details: 'Le méridien de Greenwich sert de référence pour le Temps Universel Coordonné (UTC), permettant une synchronisation précise des horloges à travers le monde. Cette standardisation est cruciale pour les communications internationales, les transports et les transactions financières mondiales.'
  },
  {
    id: 2,
    title: 'Navigation Maritime',
    description: 'Point de référence essentiel pour la navigation en mer.',
    icon: FaShip,
    details: 'Les marins utilisent le méridien de Greenwich comme point de référence pour calculer leur position en mer. Cette méthode, développée au XVIIIe siècle, reste fondamentale pour la navigation maritime moderne, même avec l\'avènement des technologies GPS.'
  },
  {
    id: 3,
    title: 'GPS et Satellites',
    description: 'Base des systèmes de positionnement par satellite.',
    icon: FaSatellite,
    details: 'Les systèmes GPS et autres technologies de navigation par satellite utilisent le méridien de Greenwich comme référence pour calculer les coordonnées géographiques. Cette standardisation permet une localisation précise partout dans le monde.'
  },
  {
    id: 4,
    title: 'Aviation',
    description: 'Coordination des vols internationaux.',
    icon: FaPlane,
    details: 'L\'aviation internationale dépend du méridien de Greenwich pour la planification des vols, la gestion du trafic aérien et la coordination des fuseaux horaires. Cela assure la sécurité et l\'efficacité du transport aérien mondial.'
  },
  {
    id: 5,
    title: 'Cartographie',
    description: 'Standard pour les systèmes de coordonnées.',
    icon: FaGlobeEurope,
    details: 'Le méridien de Greenwich est le point de référence 0° pour toutes les cartes modernes. Cette standardisation permet une représentation cohérente de la Terre et facilite la création de cartes précises pour diverses applications.'
  },
  {
    id: 6,
    title: 'Applications Mobiles',
    description: 'Services basés sur la localisation.',
    icon: FaMobileAlt,
    details: 'Les applications mobiles modernes utilisent le système de coordonnées basé sur le méridien de Greenwich pour fournir des services de localisation précis, de la navigation aux recommandations locales en passant par les prévisions météorologiques.'
  }
];

const Applications = () => {
  const [selectedApp, setSelectedApp] = useState(null);

  return (
    <ApplicationsContainer>
      <ApplicationsContent>
        <ApplicationsHeader>
          <h1>Applications Modernes</h1>
          <p>
            Découvrez comment le méridien de Greenwich continue d'influencer notre vie quotidienne à travers diverses applications technologiques.
          </p>
        </ApplicationsHeader>

        <ApplicationsGrid>
          {applications.map((app) => (
            <ApplicationCard
              key={app.id}
              onClick={() => setSelectedApp(app)}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <app.icon />
              <h3>{app.title}</h3>
              <p>{app.description}</p>
            </ApplicationCard>
          ))}
        </ApplicationsGrid>

        <AnimatePresence>
          {selectedApp && (
            <DetailModal
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setSelectedApp(null)}
            >
              <ModalContent
                onClick={(e) => e.stopPropagation()}
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
              >
                <h2>{selectedApp.title}</h2>
                <p>{selectedApp.details}</p>
                <button onClick={() => setSelectedApp(null)}>Fermer</button>
              </ModalContent>
            </DetailModal>
          )}
        </AnimatePresence>
      </ApplicationsContent>
    </ApplicationsContainer>
  );
};

export default Applications;
