import styled from 'styled-components';
import { motion } from 'framer-motion';

const AboutContainer = styled.div`
  padding: 120px 0 4rem;
  min-height: 100vh;
`;

const AboutContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const AboutHeader = styled.div`
  text-align: center;
  margin-bottom: 4rem;

  h1 {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }

  p {
    color: var(--dark-gray);
    font-size: 1.1rem;
    max-width: 800px;
    margin: 0 auto;
  }
`;

const AboutSection = styled(motion.section)`
  margin-bottom: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  align-items: center;
`;

const AboutText = styled.div`
  h2 {
    color: var(--primary-color);
    margin-bottom: 1rem;
    font-size: 2rem;
  }

  p {
    color: var(--dark-gray);
    line-height: 1.8;
    margin-bottom: 1.5rem;
  }
`;

const AboutImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const AboutStats = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
  text-align: center;
`;

const StatItem = styled(motion.div)`
  padding: 2rem;
  background-color: var(--light-gray);
  border-radius: 8px;

  h3 {
    color: var(--accent-color);
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }

  p {
    color: var(--dark-gray);
    font-size: 1.1rem;
  }
`;

const About = () => {
  return (
    <AboutContainer>
      <AboutContent>
        <AboutHeader>
          <h1>À Propos du Méridien de Greenwich</h1>
          <p>
            Découvrez l'histoire et l'importance du méridien qui a révolutionné notre façon de mesurer le temps et l'espace.
          </p>
        </AboutHeader>

        <AboutSection
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <AboutText>
            <h2>Notre Mission</h2>
            <p>
              Le méridien de Greenwich est bien plus qu'une simple ligne imaginaire sur la Terre. Il représente une révolution dans la façon dont l'humanité mesure le temps et navigue à travers le monde. Notre mission est de préserver et de partager cette histoire fascinante tout en explorant ses applications modernes.
            </p>
            <p>
              Nous nous engageons à rendre accessible à tous la richesse historique et scientifique du méridien de Greenwich, tout en mettant en lumière son rôle crucial dans le développement des technologies modernes de navigation et de synchronisation temporelle.
            </p>
          </AboutText>
          <AboutImage
            src="/images/greenwich-observatory.jpg"
            alt="Observatoire de Greenwich"
          />
        </AboutSection>

        <AboutStats>
          <StatItem
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4, delay: 0.2 }}
          >
            <h3>1884</h3>
            <p>Année d'adoption officielle</p>
          </StatItem>
          <StatItem
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4, delay: 0.4 }}
          >
            <h3>24</h3>
            <p>Fuseaux horaires définis</p>
          </StatItem>
          <StatItem
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4, delay: 0.6 }}
          >
            <h3>180°</h3>
            <p>De longitude couverte</p>
          </StatItem>
        </AboutStats>

        <AboutSection
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <AboutImage
            src="/images/greenwich-line.jpg"
            alt="Ligne du méridien de Greenwich"
          />
          <AboutText>
            <h2>Notre Vision</h2>
            <p>
              Nous aspirons à créer une plateforme éducative et interactive qui permet aux visiteurs de comprendre l'importance historique du méridien de Greenwich et son impact continu sur notre vie quotidienne.
            </p>
            <p>
              À travers des contenus riches et variés, nous souhaitons inspirer la prochaine génération de scientifiques, d'historiens et de passionnés à explorer et à préserver cet héritage unique de l'humanité.
            </p>
          </AboutText>
        </AboutSection>
      </AboutContent>
    </AboutContainer>
  );
};

export default About;
