import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Stars } from '@react-three/drei';
import { FaBook, FaSatellite, FaCalendarAlt } from 'react-icons/fa';

// Styled Components
const HomeContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-top: 80px; // Account for fixed navbar
`;

const HeroSection = styled.section`
  width: 100%;
  height: 80vh;
  position: relative;
  background-color: #000;
  color: white;
  overflow: hidden;
`;

const HeroContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  width: 90%;
  max-width: 800px;
  padding: 0 1rem;
`;

const HeroTitle = styled.h1`
  font-size: clamp(2rem, 5vw, 3rem);
  margin-bottom: 1rem;
  color: white;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: clamp(1rem, 2vw, 1.2rem);
  margin-bottom: 2rem;
  opacity: 0.9;
`;

const CTAButton = styled.button`
  background-color: var(--accent-color);
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--primary-color);
  }
`;

const FeaturesSection = styled.section`
  width: 100%;
  padding: 4rem 0;
  background-color: var(--light-gray);
`;

const FeaturesContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;

  @media (max-width: 1200px) {
    padding: 0 2rem;
  }

  @media (max-width: 768px) {
    padding: 0 1rem;
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  svg {
    font-size: 2.5rem;
    color: var(--accent-color);
    margin-bottom: 1rem;
  }

  h3 {
    margin-bottom: 1rem;
  }

  p {
    color: var(--dark-gray);
    line-height: 1.6;
  }
`;

// Three.js Components
const Earth = () => {
  const earthRef = useRef();

  useEffect(() => {
    if (earthRef.current) {
      earthRef.current.rotation.y += 0.002;
    }
  });

  return (
    <mesh ref={earthRef}>
      <sphereGeometry args={[2, 32, 32]} />
      <meshStandardMaterial color="#4a90e2" wireframe />
    </mesh>
  );
};

const Scene = () => {
  return (
    <>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <Earth />
      <Stars radius={100} depth={50} count={5000} factor={4} saturation={0} fade />
      <OrbitControls enableZoom={false} enablePan={false} />
    </>
  );
};

const Home = () => {
  const scrollToFeatures = () => {
    document.querySelector('#features').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <HomeContainer>
      <HeroSection>
        <Canvas>
          <Scene />
        </Canvas>
        <HeroContent>
          <HeroTitle>Greenwich Meridian</HeroTitle>
          <HeroSubtitle>Découvrez le Cœur du Temps Mondial</HeroSubtitle>
          <CTAButton onClick={scrollToFeatures}>En Savoir Plus</CTAButton>
        </HeroContent>
      </HeroSection>

      <FeaturesSection id="features">
        <FeaturesContainer>
          <FeatureCard>
            <FaBook />
            <h3>Histoire du Méridien</h3>
            <p>Découvrez l'histoire fascinante du méridien de Greenwich et son rôle crucial dans la standardisation du temps mondial.</p>
          </FeatureCard>

          <FeatureCard>
            <FaSatellite />
            <h3>Applications Modernes</h3>
            <p>Explorez les applications contemporaines du méridien dans la navigation, la technologie GPS et la synchronisation mondiale.</p>
          </FeatureCard>

          <FeatureCard>
            <FaCalendarAlt />
            <h3>Événements et Célébrations</h3>
            <p>Participez aux événements et célébrations qui mettent en valeur l'importance historique et culturelle du méridien.</p>
          </FeatureCard>
        </FeaturesContainer>
      </FeaturesSection>
    </HomeContainer>
  );
};

export default Home;
