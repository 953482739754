import styled from 'styled-components';
import { motion } from 'framer-motion';

const HistoryContainer = styled.div`
  padding: 120px 0 4rem;
  min-height: 100vh;
`;

const HistoryContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const HistoryHeader = styled.div`
  text-align: center;
  margin-bottom: 4rem;

  h1 {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }

  p {
    color: var(--dark-gray);
    font-size: 1.1rem;
    max-width: 800px;
    margin: 0 auto;
  }
`;

const Timeline = styled.div`
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 0;

  &::before {
    content: '';
    position: absolute;
    width: 2px;
    background-color: var(--accent-color);
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 768px) {
    &::before {
      left: 2rem;
    }
  }
`;

const TimelineItem = styled(motion.div)`
  padding: 1rem 3rem 1rem 0;
  position: relative;
  width: 50%;
  left: ${({ align }) => (align === 'right' ? '50%' : '0')};

  &::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: var(--accent-color);
    border-radius: 50%;
    right: ${({ align }) => (align === 'right' ? 'auto' : '-10px')};
    left: ${({ align }) => (align === 'right' ? '-10px' : 'auto')};
    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-width: 768px) {
    width: calc(100% - 4rem);
    padding: 1rem 0 1rem 4rem;
    left: 0;

    &::before {
      left: 1.9rem;
    }
  }
`;

const TimelineContent = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h3 {
    color: var(--primary-color);
    margin-bottom: 0.5rem;
  }

  .date {
    color: var(--accent-color);
    font-weight: 500;
    margin-bottom: 1rem;
  }

  p {
    color: var(--dark-gray);
    line-height: 1.6;
  }
`;

const timelineEvents = [
  {
    year: '1675',
    title: 'Création de l\'Observatoire Royal de Greenwich',
    description: 'Charles II d\'Angleterre fonde l\'Observatoire Royal de Greenwich pour améliorer la navigation maritime.'
  },
  {
    year: '1851',
    title: 'Premier Méridien de Greenwich',
    description: 'Sir George Airy établit le méridien de Greenwich comme référence pour la navigation britannique.'
  },
  {
    year: '1884',
    title: 'Conférence Internationale du Méridien',
    description: 'Le méridien de Greenwich est adopté comme méridien principal mondial lors de la conférence à Washington.'
  },
  {
    year: '1960',
    title: 'Adoption du Temps Universel Coordonné (UTC)',
    description: 'Le système UTC basé sur le méridien de Greenwich devient la référence mondiale pour le temps.'
  },
  {
    year: '1999',
    title: 'Inscription au Patrimoine Mondial',
    description: 'L\'Observatoire Royal de Greenwich est inscrit au patrimoine mondial de l\'UNESCO.'
  },
  {
    year: 'Aujourd\'hui',
    title: 'Centre de Navigation Mondiale',
    description: 'Le méridien de Greenwich reste la référence pour la navigation GPS et les systèmes de positionnement modernes.'
  }
];

const History = () => {
  return (
    <HistoryContainer>
      <HistoryContent>
        <HistoryHeader>
          <h1>Histoire du Méridien de Greenwich</h1>
          <p>
            Découvrez les moments clés qui ont fait du méridien de Greenwich une référence mondiale pour la mesure du temps et de l'espace.
          </p>
        </HistoryHeader>

        <Timeline>
          {timelineEvents.map((event, index) => (
            <TimelineItem
              key={event.year}
              align={index % 2 === 0 ? 'left' : 'right'}
              initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <TimelineContent>
                <div className="date">{event.year}</div>
                <h3>{event.title}</h3>
                <p>{event.description}</p>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </HistoryContent>
    </HistoryContainer>
  );
};

export default History;
