import { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaCalendarAlt, FaClock, FaMapMarkerAlt, FaTicketAlt } from 'react-icons/fa';

const EventsContainer = styled.div`
  padding: 120px 0 4rem;
  min-height: 100vh;
`;

const EventsContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const EventsHeader = styled.div`
  text-align: center;
  margin-bottom: 4rem;

  h1 {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }

  p {
    color: var(--dark-gray);
    font-size: 1.1rem;
    max-width: 800px;
    margin: 0 auto;
  }
`;

const EventsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const EventCard = styled(motion.div)`
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const EventImage = styled.div`
  height: 200px;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
`;

const EventContent = styled.div`
  padding: 1.5rem;

  h3 {
    color: var(--primary-color);
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  p {
    color: var(--dark-gray);
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
`;

const EventMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--accent-color);
  margin-bottom: 0.5rem;
  font-size: 0.9rem;

  svg {
    font-size: 1rem;
  }
`;

const RegisterButton = styled.button`
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  justify-content: center;

  &:hover {
    background-color: var(--primary-color);
  }

  svg {
    font-size: 1.1rem;
  }
`;

const FilterSection = styled.div`
  margin-bottom: 2rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

const FilterButton = styled.button`
  background-color: ${({ active }) => active ? 'var(--accent-color)' : 'white'};
  color: ${({ active }) => active ? 'white' : 'var(--text-color)'};
  border: 1px solid var(--accent-color);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--accent-color);
    color: white;
  }
`;

const events = [
  {
    id: 1,
    title: 'Conférence sur l\'Histoire du Méridien',
    description: 'Une exploration fascinante de l\'histoire du méridien de Greenwich et son impact sur la navigation mondiale.',
    date: '2024-01-15',
    time: '14:00',
    location: 'Observatoire de Paris',
    image: '/images/conference.jpg',
    category: 'conference'
  },
  {
    id: 2,
    title: 'Atelier de Navigation Astronomique',
    description: 'Apprenez les techniques traditionnelles de navigation en utilisant le méridien de Greenwich comme référence.',
    date: '2024-02-01',
    time: '10:00',
    location: 'École Maritime de Nantes',
    image: '/images/workshop.jpg',
    category: 'workshop'
  },
  {
    id: 3,
    title: 'Exposition : Le Temps à Travers les Âges',
    description: 'Une exposition interactive sur l\'évolution de la mesure du temps et le rôle du méridien de Greenwich.',
    date: '2024-03-10',
    time: '09:00',
    location: 'Musée des Sciences',
    image: '/images/exhibition.jpg',
    category: 'exhibition'
  },
  {
    id: 4,
    title: 'Célébration du Jour du Méridien',
    description: 'Une journée spéciale dédiée à la célébration du méridien de Greenwich avec des activités pour toute la famille.',
    date: '2024-04-20',
    time: '11:00',
    location: 'Place de l\'Observatoire',
    image: '/images/celebration.jpg',
    category: 'celebration'
  }
];

const categories = [
  { id: 'all', label: 'Tous les événements' },
  { id: 'conference', label: 'Conférences' },
  { id: 'workshop', label: 'Ateliers' },
  { id: 'exhibition', label: 'Expositions' },
  { id: 'celebration', label: 'Célébrations' }
];

const Events = () => {
  const [activeCategory, setActiveCategory] = useState('all');

  const filteredEvents = activeCategory === 'all'
    ? events
    : events.filter(event => event.category === activeCategory);

  return (
    <EventsContainer>
      <EventsContent>
        <EventsHeader>
          <h1>Événements</h1>
          <p>
            Participez à nos événements pour en apprendre davantage sur le méridien de Greenwich
            et son importance dans le monde moderne.
          </p>
        </EventsHeader>

        <FilterSection>
          {categories.map(category => (
            <FilterButton
              key={category.id}
              active={activeCategory === category.id}
              onClick={() => setActiveCategory(category.id)}
            >
              {category.label}
            </FilterButton>
          ))}
        </FilterSection>

        <EventsGrid>
          {filteredEvents.map((event) => (
            <EventCard
              key={event.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <EventImage image={event.image} />
              <EventContent>
                <h3>{event.title}</h3>
                <EventMeta>
                  <FaCalendarAlt />
                  {new Date(event.date).toLocaleDateString('fr-FR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </EventMeta>
                <EventMeta>
                  <FaClock />
                  {event.time}
                </EventMeta>
                <EventMeta>
                  <FaMapMarkerAlt />
                  {event.location}
                </EventMeta>
                <p>{event.description}</p>
                <RegisterButton>
                  <FaTicketAlt />
                  S'inscrire
                </RegisterButton>
              </EventContent>
            </EventCard>
          ))}
        </EventsGrid>
      </EventsContent>
    </EventsContainer>
  );
};

export default Events;
